import React from "react";
import githublogo from "./github-11-xxl.png"
import "./style.css"

function Github() {
    return <div >
        <a href="https://github.com/rythomas2525" target="_blank" rel="noopener noreferrer" title="github"><img src={githublogo} alt="github" className="bottomicons github " /></a>
    </div>
}





export default Github;
