import React from "react";
import "./style.css"





function Aboutmetext() {
    return (


        <div id="aboutmetext" className="mt-4 text-white text-center">
            <p>Hi, I’m Ryan Thomas. I started a vape juice company when I was 19, and ran it
            successfully for seven years.
            However, I made the switch to web development and haven't looked back. I specialize
            in
              HTML, CSS, JavaScript, JQuery, React.js, MySQL, MongoDB, and NODE.js.</p>
        </div>

    );
};

export default Aboutmetext;