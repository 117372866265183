import React from "react";

import "./style.css"

function Overlay() {
    return <div id="overlay">
    </div>
}





export default Overlay;
